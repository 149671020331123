var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"name":_vm.name,"rules":_vm.faildRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',_vm._b({staticClass:"ek-form-group",attrs:{"label-for":'label-for-select-' + _vm.id}},'b-form-group',_vm.$attrs,false),[_c('v-select',{staticClass:"rounded",class:[_vm.fieldClass, { 'border-danger': errors[0] }],attrs:{"id":'label-for-select-' + _vm.id,"options":_vm.options,"reduce":function (item) { return (_vm.valueLabel ? item[_vm.valueLabel] : item); },"label":_vm.textLabel,"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"clearable":_vm.clearable,"multiple":_vm.multiple,"placeholder":_vm.placeholder,"selectable":_vm.selectable},scopedSlots:_vm._u([{key:"list-header",fn:function(){return [_vm._t("list-header")]},proxy:true},{key:"option",fn:function(ref){
var name = ref.name;
return [_vm._t("option",null,{"label":name})]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_vm._t("selected-option",null,{"label":name})]}},{key:"list-footer",fn:function(){return [_vm._t("list-footer")]},proxy:true},{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [_vm._t("no-options",null,{"search":search,"searching":searching})]}}],null,true),model:{value:(_vm.innerVal),callback:function ($$v) {_vm.innerVal=$$v},expression:"innerVal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }